<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrando</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>registros</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="8"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="query"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
              />
              <v-select
                v-model="statusFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="descripcion"
                :options="statusOptions"
                class="invoice-filter-select d-inline-block mr-1"
                placeholder="Filtros Ose"
                @input="chageStatusFilter"
              />
              <!-- <b-button
                variant="success"
                @click="generarReportes()"
              >
                <span class="text-nowrap">Reportes</span>
              </b-button> -->
              <b-button
                variant="primary"
                @click="openForm('new')"
              >
                <span class="text-nowrap">Nuevo</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="items"
        responsive
        :fields="fields"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No se encontraron registros"
        :busy="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner
              variant="primary"
              class="align-middle"
            />
            <strong class="ml-1">Cargando...</strong>
          </div>
        </template>
        <template #cell(razonSocial)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="`data:image/jpeg;base64,${data.item.foto}`"
                :text="avatarText(`${data.item.razonSocial}`)"
                :variant="`light-${getColorAvatar()}`"
              />
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.razonSocial }}
            </span>
            <small class="text-muted">{{ data.item.representanteLegal }}</small>
            <small class="text-muted d-block ">{{ data.item.tipoDocumento.abreviatura }} {{ data.item.documento }}</small>
          </b-media>
        </template>
        <!-- Column: Actions -->
        <template #cell(nroComprobante)="data">
          <div class="d-flex align-items-center">
            <div>
              <div class="font-small-4 font-weight-bolder">
                {{ data.item.nroComprobante }}
              </div>
              <div class="font-small-3 text-muted">
                Fecha : {{ data.item.fechaEmision }}
              </div>
            </div>
          </div>
        </template>
        <template #cell(nombreProveedor)="data">
          <div class="d-flex align-items-center">
            <div>
              <div class="font-small-3 font-weight-bolder">
                {{ data.item.nombreProveedor }}
              </div>
              <div class="font-small-2 text-muted">
                RUC : {{ data.item.rucProveedor }}
              </div>
            </div>
          </div>
        </template>
        <template #cell(subTotal)="data">
          <div class="font-small-3">
            {{ formatearMoneda(data.item.subTotal) }}
          </div>
        </template>
        <template #cell(total)="data">
          <div class="font-small-3">
            {{ formatearMoneda(data.item.total) }}
          </div>
        </template>
        <template #cell(montoIgv)="data">
          <div class="font-small-3">
            {{ formatearMoneda(data.item.montoIgv) }}
          </div>
        </template>
        <template #cell(percepcion)="data">
          <div class="font-small-3">
            {{ formatearMoneda(data.item.percepcion) }}
          </div>
        </template>
        <template #cell(totalFise)="data">
          <div class="font-small-3">
            {{ formatearMoneda(data.item.totalFise) }}
          </div>
        </template>
        <template #cell(acciones)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              @click="deleteVenta(data)"
            >
              <feather-icon icon="XSquareIcon" />
              <span class="align-middle ml-50">Anular</span>
            </b-dropdown-item>
            <!-- <b-dropdown-item
                v-if="data.item.repuestaOse != null && data.item.fechaAnulacion == null"
                @click="generarGuia(data)"
              >
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Generar Guia</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.repuestaOse != null && data.item.fechaAnulacion == null"
                @click="generarNota(data)"
              >
                <feather-icon icon="BookIcon" />
                <span class="align-middle ml-50">Generar Nota</span>
              </b-dropdown-item> -->
            <b-dropdown-item
              v-b-modal.modal-lg
              @click="getCompra(data)"
            >
              <feather-icon icon="PrinterIcon" />
              <span class="align-middle ml-50">Ver compra</span>
            </b-dropdown-item>

            <!-- <b-dropdown-item @click="getEdit(data)">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Ver</span>
              </b-dropdown-item>

              <b-dropdown-item @click="getEdit(data)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Editar</span>
              </b-dropdown-item>

              <b-dropdown-item @click="getDelete(data)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Eliminar</span>
              </b-dropdown-item> -->
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando {{ from }} a {{ to }} de {{ of }} registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="page"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <b-modal
      id="modal-lg"
      ok-only
      ok-title="Accept"
      centered
      size="xl"
      title="Ver Compra"
    >
      <b-row>

        <b-col
          v-if="compraGet !== null"
          cols="12"
          md="2"
          class="mb-1 mb-md-0"
        >
          <b-form-group
            label="CodigoProveedor"
            label-for="codigoProveedor"
          >
            <b-form-input
              :value="proveedorDato[0]"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="compraGet !== null"
          cols="12"
          md="4"
          class="mb-1 mb-md-0"
        >
          <b-form-group
            label="Proveedor"
            label-for="nombreProveedor"
          >
            <b-form-input
              :value="compraGet.nombreProveedor"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="compraGet !== null"
          cols="12"
          md="2"
          class="mb-1 mb-md-0"
        >
          <b-form-group
            label="Proveedor RUC"
            label-for="rucProveedor"
          >
            <b-form-input
              :value="compraGet.rucProveedor"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="compraGet !== null"
          cols="12"
          md="4"
          class="mb-1 mb-md-0"
        >
          <b-form-group
            label="Proveedor Direccion"
            label-for="direccionProveedor"
          >
            <b-form-input
              :value="proveedorDato[3]"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="compraGet !== null"
          cols="12"
          md="2"
          class="mb-1 mb-md-0"
        >
          <b-form-group
            label="Fecha Emision"
            label-for="fechaEmision"
          >
            <b-form-input
              :value="compraGet.fechaEmision"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="compraGet !== null"
          cols="12"
          md="2"
          class="mb-1 mb-md-0"
        >
          <b-form-group
            label="Condicion Pago"
            label-for="condicionPago"
          >
            <b-form-input
              :value="compraGet.condicionPago"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="compraGet !== null"
          cols="12"
          md="2"
          class="mb-1 mb-md-0"
        >
          <b-form-group
            label="Fecha Vencimiento"
            label-for="fechaVencimiento"
          >
            <b-form-input
              :value="compraGet.fechaVencimiento"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="compraGet !== null"
          cols="12"
          md="2"
          class="mb-1 mb-md-0"
        >
          <b-form-group
            label="Codigo Ubigeo Proveedor"
            label-for="ubigeoProveedor"
          >
            <b-form-input
              :value="compraGet.nroCuotaPago"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="compraGet !== null"
          cols="12"
          md="2"
          class="mb-1 mb-md-0"
        >
          <b-form-group
            label="Nro Cuotas Pago"
            label-for="cuotaPago"
          >
            <b-form-input
              :value="compraGet.nroCuotaPago"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="compraGet !== null"
          cols="12"
          md="2"
          class="mb-1 mb-md-0"
        >
          <b-form-group
            label="Nro Comprobante"
            label-for="nroComprobante"
          >
            <b-form-input
              :value="compraGet.nroComprobante"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="compraGet !== null"
          cols="12"
          md="3"
          class="mb-1 mb-md-0"
        >
          <b-form-group
            label="Ruc Cliente"
            label-for="rucCliente"
          >
            <b-form-input
              :value="clienteDato[1]"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="compraGet !== null"
          cols="12"
          md="4"
          class="mb-1 mb-md-0"
        >
          <b-form-group
            label="Cliente"
            label-for="nombreCliente"
          >
            <b-form-input
              :value="clienteDato[0]"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="compraGet !== null"
          cols="12"
          md="5"
          class="mb-1 mb-md-0"
        >
          <b-form-group
            label="Direccion Cliente"
            label-for="direccionCliente"
          >
            <b-form-input
              :value="clienteDato[2]"
              readonly
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="12"
          class="mb-1 mt-2 mb-md-0"
        >
          <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="itemsCompra"
            responsive
            :fields="fieldsDetalle"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="No se encontraron registros"
            :busy="isBusy"
          >
            <!-- Column: precio -->
            <template #cell(productoNombre)="data">
              <b-form-input
                :value="data.item.productoDato"
                :disabled="true"
                size="sm"
              />
            </template>
            <template #cell(inputUnidadMedida)="data">
              <b-form-input
                :value="data.item.unidadMedida"
                :disabled="true"
                size="sm"
              />
            </template>
            <!-- Column: Cantidad -->
            <template #cell(inputCantidad)="data">
              <b-form-input
                :value="data.item.cantidad"
                type="number"
                size="sm"
                :disabled="true"
              />
            </template>
            <!-- Column: precio -->
            <template #cell(inputPrecio)="data">
              <b-form-input
                :value="data.item.precioVenta"
                size="sm"
                :disabled="true"
                type="number"
              />
            </template>
            <!-- Column: precio -->
            <template #cell(inputIgv)="data">
              <b-form-input
                :value="data.item.igv"
                size="sm"
                :disabled="true"
                type="number"
              />
            </template>
            <!-- Column: Subtotal -->
            <template #cell(inputSubtotal)="data">
              <b-form-input
                :value="data.item.subTotal"
                readonly
                size="sm"
                :disabled="true"
              />
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="6"
        />
        <b-col
          v-if="compraGet !== null"
          cols="12"
          md="2"
          class="mb-1 mt-1 mb-md-0"
        >
          <b-form-group
            label="Valor de venta"
            label-for="valorVenta"
          >
            <b-form-input
              :value="compraGet.valorVenta"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="compraGet !== null"
          cols="12"
          md="2"
          class="mb-1 mt-1 mb-md-0"
        >
          <b-form-group
            label="Sub-Total"
            label-for="Sub-Total"
          >
            <b-form-input
              :value="compraGet.subTotal"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="compraGet !== null"
          cols="12"
          md="2"
          class="mb-1 mt-1 mb-md-0"
        >
          <b-form-group
            label="Total IGV"
            label-for="totalIgv"
          >
            <b-form-input
              :value="compraGet.montoIgv"
              readonly
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="6"
        />
        <b-col
          v-if="compraGet !== null"
          cols="12"
          md="2"
          class=" mb-md-0"
        >
          <b-form-group
            label="Total FISE"
            label-for="totalFise"
          >
            <b-form-input
              :value="compraGet.totalFise"
              type="number"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="compraGet !== null"
          cols="12"
          md="2"
          class=" mb-md-0"
        >
          <b-form-group
            label="Importe Total"
            label-for="importeTotal"
          >
            <b-form-input
              :value="compraGet.importeTotal"
              type="number"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="compraGet !== null"
          cols="12"
          md="2"
          class=" mb-md-0"
        >
          <b-form-group
            label="Percepcion"
            label-for="percepcion"
          >
            <b-form-input
              :value="compraGet.percepcion"
              readonly
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="10"
        />

        <b-col
          v-if="compraGet !== null"
          cols="12"
          md="2"
          class=" mb-md-0"
        >
          <b-form-group
            label="Total Cobrar"
            label-for="totalCobrar"
          >
            <b-form-input
              :value="compraGet.totalCobrar"
              readonly
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>

import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BDropdown, BDropdownItem, BPagination, BSpinner, BAvatar, BMedia, VBTooltip, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { showToast, showError } from '@/helpers'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BSpinner,
    BAvatar,
    BMedia,
    BFormGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },

  data: () => ({
    isBusy: false,
    idEmpresa: 3,
    isToggleSidebar: false,
    dataEdit: {},
    formType: '',
    page: 1,
    perPage: 10,
    totalRows: 0,
    totalItems: 0,
    totalPage: 0,
    from: 0,
    to: 0,
    of: 0,
    pageOptions: [10, 20, 50],
    sortBy: null,
    sortDesc: false,
    sortDirection: 'asc',
    query: '',
    items: [],
    itemsCompra: [],
    proveedorDato: [],
    clienteDato: [],
    compraGet: null,
    statusFilter: { id: '-1', descripcion: 'Todos' },
    statusOptions: [
      { id: '-1', descripcion: 'Todos' },
      { id: '1', descripcion: 'Enviados' },
      { id: '2', descripcion: 'Pendientes' },
      { id: '3', descripcion: 'Vigentes' },
      { id: '4', descripcion: 'Anulados' },
    ],
    userProfile: localStorage.getItem('userProfile'),
    fields: [
      { key: 'compraId', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
      { key: 'nroComprobante', label: 'Comprobante', sortable: true, thStyle: { width: '16%' } },
      { key: 'nombreProveedor', label: 'Proveedor', sortable: true, thStyle: { width: '21%' } },
      // { key: 'rucProveedor', label: 'Ruc', sortable: true, thStyle: { width: '5%' } },
      // { key: 'nroComprobante', label: 'N.Comprobante', sortable: true, thStyle: { width: '5%' } },
      // { key: 'formatoPago.descripcion', label: 'F. Pago', sortable: true, tdClass: 'text-center', thClass: 'text-center' },
      { key: 'subTotal', label: 'Subtotal', sortable: true, thStyle: { width: '12%' } },
      { key: 'montoIgv', label: 'igv', sortable: true, thStyle: { width: '11%' } },
      { key: 'percepcion', label: 'percepcion', sortable: true, thStyle: { width: '11%' } },
      { key: 'totalFise', label: 'FISE', sortable: true, thStyle: { width: '11%' } },
      { key: 'total', label: 'Total', sortable: true, thStyle: { width: '12%' } },
      { key: 'acciones' },
    ],
    fieldsDetalle: [
      { key: 'idDetalle', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
      { key: 'productoNombre', label: 'Producto', sortable: true, thStyle: { width: '35%' } },
      { key: 'inputUnidadMedida', label: 'Unidad M.', thStyle: { width: '14%' } },
      { key: 'inputCantidad', label: 'Cantidad', thStyle: { width: '12%' } },
      { key: 'inputPrecio', label: 'Precio', thStyle: { width: '12%' } },
      { key: 'inputIgv', label: 'Igv', thStyle: { width: '10%' } },
      { key: 'inputSubtotal', label: 'Subtotal', thStyle: { width: '12%' } },
    ],
  }),
  watch: {
    perPage: {
      handler() {
        this.listarRegistros()
      },
    },
    page: {
      handler() {
        this.listarRegistros()
      },
    },
    query: {
      handler() {
        this.listarRegistros()
      },
    },
  },
  mounted() {
    this.listarRegistros()
  },
  methods: {
    getPrintPdf(data) {
      if (data.item.repuestaOse !== null) {
        console.log(data.item.repuestaOse.rutaPdf)
      }
    },
    async listarRegistros() {
      console.log(this.userProfile)
      this.isBusy = true
      await store
        .dispatch('comercial/COMPRAS_FIND_ALL', {
          idEmpresa: this.idEmpresa,
          limit: this.perPage,
          query: this.query,
          page: this.page,
          almacen: -1,
          sortBy: 'compraId%7CDESC',
          filtro_venta: this.statusFilter.id,
        })
        .then(response => {
          this.items = response.items
          this.totalRows = response.totalRows
          this.totalItems = response.totalItems
          this.totalPage = response.totalPage
          this.from = this.perPage * (this.page - 1) + (this.totalRows ? 1 : 0)
          this.to = this.perPage * (this.page - 1) + this.totalRows
          this.of = this.totalItems
        })
        .catch(error => {
          this.showToast('Ha ocurrido un error', error, 'danger')
        })
      this.isBusy = false
    },
    openForm(type) {
      // this.formType = type
      // this.isToggleSidebar = true
      console.log(type)
      this.$router.push({ name: 'rt-comercial-compraform' })
    },
    getEdit(data) {
      this.formType = 'edit'
      this.isToggleSidebar = true
      console.log(data.item)
      this.dataEdit = data.item
    },
    deleteVenta(data) {
      this.$swal({
        title: 'Está seguro?',
        text: 'Va eliminar un registro!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          // const partComprobante = data.item.numeroComprobante.split('-')
          const params = { compra_id: data.item.compraId }
          this.$swal({
            title: 'Anulando la compra',
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            onOpen: async () => {
              this.$swal.showLoading()
              const service = 'comercial/COMPRA_DELETE'
              await store.dispatch(service, params)
                .then(() => {
                  // this.items.forEach((item, index) => {
                  //   if (item.ventaId === data.item.ventaId) {
                  //     this.items[index].repuestaOse = response.respuesta_ose
                  //   }
                  // })
                  this.listarRegistros()
                  this.$swal.close()
                  this.showToast('Operación Extitosa', 'El comprobante se anulo', 'success')
                })
                .catch(error => {
                  this.$swal.close()
                  console.log(error)
                  this.showToast('Operación Error', error.errorMessage, 'danger')
                })
            },
          })
        }
      })
    },
    getCompra(data) {
      console.log(data.item)
      this.compraGet = data.item
      this.itemsCompra = data.item.detalleCompra
      this.proveedorDato = (data.item.proveedorDato).split('|')
      this.clienteDato = (data.item.clienteDato).split('|')
    },
    refetchData(response) {
      let message = 'El proceso se ha ejecutado satisfactoriamente.'
      if (response.message) {
        message = response.message
      }
      this.showToast('Operación Extitosa', message, 'success')
      this.listarRegistros()
    },
    getColorAvatar() {
      const max = 7
      const min = 1
      const status = Math.floor(Math.random() * ((max + 1) - min) + min)
      if (status === 1) return 'primary'
      if (status === 2) return 'danger'
      if (status === 3) return 'secondary'
      if (status === 4) return 'warning'
      if (status === 5) return 'info'
      if (status === 6) return 'success'
      return 'primary'
    },
    // deleteVenta
    // generarGuia
    // generarNota
    // getConsultaOse
    getConsultaOse(data) {
      const partComprobante = data.item.numeroComprobante.split('-')
      const params = { nro_comprobante: partComprobante[1], serie: partComprobante[0], tipo_documento: data.item.tipoDocumento.tipoDocumentoId, venta_id: data.item.ventaId }
      this.$swal({
        title: 'Consultando en la Ose',
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: async () => {
          this.$swal.showLoading()
          const service = 'comercial/VENTA_CONSULTA_OSE'
          await store.dispatch(service, params)
            .then(response => {
              this.items.forEach((item, index) => {
                if (item.ventaId === data.item.ventaId) {
                  this.items[index].repuestaOse = response.respuesta_ose
                }
              })
              this.$swal.close()
              this.showToast('Operación Extitosa', 'El comprobante esta enviado en la Ose', 'success')
            })
            .catch(error => {
              this.$swal.close()
              console.log(error)
              this.showToast('Operación Error', error.errorMessage, 'danger')
            })
        },
      })
    },
    formatearMoneda(valor) {
      // Convertir el número en cadena con dos decimales
      const valorFormateado = valor.toFixed(2)

      // Agregar el prefijo "S/." y retornar
      return `S/. ${valorFormateado}`
    },
    chageStatusFilter() {
      console.log(this.statusFilter)
      this.listarRegistros()
    },
    avatarText,
    showToast,
    showError,
  },

}
</script>

  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  .invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
  </style>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>

  <style>
  .w200{
    width: 350px !important;
  }

  </style>
